<template>
  <v-container grid-list-md>
    <v-toolbar style="margin-bottom:1em">
      <v-toolbar-title>Technology Services Dashboard</v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col :cols="12" md="6" lg="4">
        <v-card>
          <!-- <v-card-text style="padding-bottom:0">
            <v-text-field v-model="filter" label="Filter Links" hint="Note: Filter searches both labels and links" persistent-hint outlined></v-text-field>
          </v-card-text>
          <v-list v-for="({ text, links }, index) in filteredLinks" :key="'list-' + index">
            <v-subheader :style="dark ? 'background-color:#333' : 'background-color:#eee'">{{ text }}</v-subheader>
            <v-list-item v-for="({ text, link, internal }, linkIndex) in links" :key="'link-' + index + '-' + linkIndex" :to="internal ? link : null" :href="internal ? null : link" :target="internal ? '' : '_blank'">
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item>
          </v-list> -->
          <link-list category="Tech Services" />
        </v-card>
      </v-col>
      <v-col :cols="12" md="6" lg="4">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Department Employees</v-toolbar-title>
          </v-toolbar>
          <v-list>
            <v-list-item v-for="({ name, ext, office, cell }, index) in employees" :key="'emp-' + index">
              <v-list-item-content>
                <v-list-item-title>{{ name }}</v-list-item-title>
                <v-list-item-subtitle>{{ office }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-subtitle>Extension: {{ ext }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="cell">Cell: {{ cell }}</v-list-item-subtitle>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col :cols="12" lg="4">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>GreatScots Search</v-toolbar-title>
          </v-toolbar>
          <home-directory-search></home-directory-search>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
export default {
  components: {
    HomeDirectorySearch: () => import('@/components/tech/homeDirectorySearch'),
    LinkList: () => import('@/components/system/LinkList')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const dark = computed(() => 'settings' in user.value && 'darkMode' in user.value.settings ? user.value.settings.darkMode : false)
    const roles = computed(() => root.$store.state.roles)
    const linkGroups = ref([
      {
        text: 'Departmental Links',
        links: [
          { text: 'Spiceworks', link: 'https://on.spiceworks.com/tickets' },
          { text: 'ReACT Helpdesk', link: 'https://apps04.covenant.edu/reacthd/' },
          { text: 'Google Chat', link: 'https://mail.google.com/chat/u/0/#chat/space/AAAAf7wBhXk' },
          { text: 'All Departmental Documentation', link: 'https://drive.google.com/drive/folders/1SuOEmCJonWu-H_rgSoKs9AQRGL9OyCfH' },
          { text: 'AV Classroom Instructions', link: 'https://drive.google.com/drive/folders/1VlP41wVDg9fI9X-cOS9Psp0leGNQoKD4?usp=sharing' },
          { text: 'PaperCut', link: 'https://scotsprint.covenant.edu:9192/admin' },
          { text: 'SmartSheet', link: 'https://app.smartsheet.com/' },
          { text: 'Banner time card', link: 'https://knox01.covenant.edu:9543/EmployeeSelfService' },
          { text: 'Canvas', link: 'https://covenantcollege.instructure.com/login/' },
          { text: 'General public info', link: 'https://www.covenant.edu/students/resources/technology' },
          { text: 'Trello Project Management', link: 'https://trello.com/b/B09YhLox/technology-services' },
          // { text: 'WiFi Guest Pass', link: 'https://cloudpath.covenant.edu/portal/sponsor/CovenantCollege/login' },
          { text: 'ScotsWork Handbook', link: 'https://www.covenant.edu/pdf/scotswork/Student-Employment-Handbook.pdf' },
          { text: 'Student Team Schedules', link: 'https://drive.google.com/drive/folders/1E0cPXt0_I3sT5VmDkJlLBYZhlTooW-MU' }
        ]
      },
      /************************************/
      {
        text: 'Forms',
        links: [
          { text: 'Classroom Check form', link: '/forms/AVClassroomChecks', internal: true },
          { text: 'Inventory form', link: '/forms/ts-inventory', internal: true }
        ]
      }
    ])

    const filter = ref('')
    const filteredLinks = computed(() => {
      if (filter.value === '') return linkGroups.value
      const arr = []
      const regex = new RegExp(filter.value, 'ig')
      for (const { text, links } of linkGroups.value) {
        const list = []
        for (const { text, link, internal } of links) {
          if (regex.test(text) || regex.test(link)) {
            list.push({ text, link, internal })
          }
        }
        if (list.length > 0) {
          arr.push({ text, links: list })
        }
      }
      return arr
    })

    const tab = ref(0)
    const employees = ref([
      { name: 'Crocker, Marjorie', ext: '1544', office: 'MH122', cell: '423-208-2627' },
      { name: 'Leauby, Brandan', ext: '1540', office: 'MH110' },
      { name: 'Locke, Randy', ext: '1546', office: 'MH123' },
      { name: 'Lynch, Paula', ext: '1543', office: 'MH120' },
      { name: 'Mitchell, Ben', ext: '1538', office: 'MH123' },
      { name: 'Moon, Jon', ext: '1556', office: 'MH120' },
      { name: 'Ray, Ben', ext: '1658', office: 'MH110' },
      { name: 'Troxell, Mike', ext: '1541', office: 'MH110' },
      { name: 'Wright, Matt', ext: '1557', office: 'MH110' },
      { name: 'AV Helpdesk', ext: '1220', office: 'MH110' },
      { name: 'Helpdesk', ext: '1219', office: 'MH110' }
    ])

    return {
      user,
      dark,
      roles,
      filter,
      filteredLinks,
      employees,
      tab
    }
  }
}
</script>
